import { createSlice } from "@reduxjs/toolkit";
import { getData } from "../../services";

export const accessSlice = createSlice({
  name: "accessData",
  initialState: {
    accessData: {},
  },
  reducers: {
    setAccessData: (state, action) => {
      state.accessData = action.payload;
    },
  },
});

export const { setAccessData } = accessSlice.actions;
export const stateSelector = (state) => state?.accessSlice?.accessData;

export const fetchAccessData = (role) => async (dispatch) => {
  try {
    const res = await getData({
      endpoint: "AdminConfiguration/getAllAccessByRole",
      params: { role_id: role },
    });

    const data = res.data

    const transformedData = data.reduce((acc, obj) => {
      const { module, ...permissions } = obj; // Extract module and permissions
      acc[module] = permissions; // Assign permissions to the module key
      return acc;
    }, {});
    dispatch(setAccessData(transformedData));
  } catch (error) {}
};
export default accessSlice.reducer;
