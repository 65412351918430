import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { patchData, postData } from "../../../../../../services";
import { statusData, userRoles } from "../../../../../../helpers/staticData";
import SelectDropdown from "../../../../../molecules/Dropdown";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";
import { getRolesList } from "../../../../../../services/basicGets";

const UserForm = ({ onClose, initialValues, id, refetchData }) => {
  const [cookies] = useCookies(["t"]);
  const [rolesData, setRolesData] = useState([]);

  const initialData = {
    name: initialValues?.name || null,
    email: initialValues?.email || null,
    role_id: initialValues?.role_id || null,
    password: null,
    is_active: initialValues?.is_active || null,
  };

  useEffect(() => {
    (async () => {
      const rolData = await getRolesList({
        token: cookies.t,
      });
      let valueLabelForm = rolData.map((item) => ({
        value: item.value,
        label: item.label,
      }));
      setRolesData(valueLabelForm);
    })();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    role_id: Yup.number().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    // console.log("values", values);

    const res = await postData({
      endpoint: "AdminUserManagement/addUser",
      token: cookies.t,
      data: values,
    });

    if (res?.data?.status) {
      toast.success("User Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
      onClose();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "AdminUserManagement/editUser",
        token: cookies.t,
        data: values,
        params: { id: id },
      });

      if (res.status) {
        toast.success("User Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Field type="text" id="name" name="name" placeholder="Enter Name" />
          </div>
          {initialValues == null && (
            <div className="form-group mt-3">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email"
              />
            </div>
          )}

          <div className="form-group mt-3">
            <label htmlFor="role_id">Role</label>
            <SelectDropdown
              data={rolesData}
              placeholder="Select Role"
              onChange={(option) => {
                setFieldValue("role_id", option);
              }}
              selectedValue={initialValues?.role_id}
            />
          </div>

          {initialValues && (
            <div className="form-group mt-3">
              <label htmlFor="role_id">Status</label>
              <SelectDropdown
                data={statusData}
                placeholder="Select Status"
                onChange={(option) => {
                  setFieldValue("is_active", option);
                }}
                selectedValue={initialValues?.is_active}
              />
            </div>
          )}

          <div className="d-flex justify-content-end mt-3">
            {initialValues ? (
              <button
                type="button"
                className="btn btn-primary"
                disabled={!isValid}
                onClick={() => handleEdit(values)}
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary w-20"
                disabled={!isValid || !dirty}
              >
                Add
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
