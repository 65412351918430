import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import AuthGuard from "./AuthGuard";
import GuestGuard from "./GuestGuard";

// Layouts
import AuthLayout from "../components/layouts/AuthLayout";
import DashboardLayout from "../components/layouts/DashboardLayout";

import None from "../components/pages/none";

// Auth
import Login from "../components/pages/auth/login";
import Verification from "../components/pages/auth/verification";
import ResetOrCreatePass from "../components/pages/auth/resetOrCreatePass";
import ForgotPassword from "../components/pages/auth/forgotPassword";

// Dashboard
import Home from "../components/pages/dashboard/home";

import Business from "../components/pages/dashboard/business";
import AddBusiness from "../components/pages/dashboard/business/Add";
import ViewBusiness from "../components/pages/dashboard/business/View";

import Plans from "../components/pages/dashboard/plans";
import AddPlan from "../components/pages/dashboard/plans/Add";
import ViewPlan from "../components/pages/dashboard/plans/View";

import Payments from "../components/pages/dashboard/payments";
import AddPayment from "../components/pages/dashboard/payments/Add";
import ViewPayment from "../components/pages/dashboard/payments/View";

import Configurations from "../components/pages/dashboard/configurations";

import AccessMangement from "../components/pages/dashboard/access-management";
import AddAccess from "../components/pages/dashboard/access-management/Add";

import NotFound from "../components/pages/dashboard/not-found";

import ChangePassword from "../components/pages/dashboard/edit-profile/change-password";
import Edit from "../components/pages/dashboard/edit-profile";

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true, // Make this the index route
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify",
        element: <Verification />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetOrCreatePass type="reset" />,
      },
      {
        path: "create-password",
        element: <ResetOrCreatePass type="create" />,
      },
    ],
  },
  {
    path: "dashboard/*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
  },
  {
    path: "none",
    element: <None />,
  },
];

export const dashboardRoute = (
  <Suspense>
    <Routes>
      <Route
        // path="/"
        index
        element={
          <AuthGuard>
            <Home />
          </AuthGuard>
        }
      />
      <Route
        path="home"
        element={
          <AuthGuard>
            <Home />
          </AuthGuard>
        }
      />
      <Route
        path="business"
        element={
          <AuthGuard>
            <Business />
          </AuthGuard>
        }
      />
      <Route
        path="business/add"
        element={
          <AuthGuard>
            <AddBusiness />
          </AuthGuard>
        }
      />
      <Route
        path="business/edit/:id"
        element={
          <AuthGuard>
            <AddBusiness type="edit" />
          </AuthGuard>
        }
      />

      <Route
        path="business/view/:id"
        element={
          <AuthGuard>
            <ViewBusiness />
          </AuthGuard>
        }
      />

      <Route
        path="plans"
        element={
          <AuthGuard>
            <Plans />
          </AuthGuard>
        }
      />
      <Route
        path="plans/add"
        element={
          <AuthGuard>
            <AddPlan />
          </AuthGuard>
        }
      />
      <Route
        path="plans/edit/:id"
        element={
          <AuthGuard>
            <AddPlan type="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="plans/view/:id"
        element={
          <AuthGuard>
            <ViewPlan />
          </AuthGuard>
        }
      />
      <Route
        path="payments"
        element={
          <AuthGuard>
            <Payments />
          </AuthGuard>
        }
      />
      <Route
        path="payments/add"
        element={
          <AuthGuard>
            <AddPayment />
          </AuthGuard>
        }
      />
      <Route
        path="payments/edit/:id"
        element={
          <AuthGuard>
            <AddPayment type="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="payments/view/:id"
        element={
          <AuthGuard>
            <ViewPayment />
          </AuthGuard>
        }
      />
      <Route
        path="configurations"
        element={
          <AuthGuard>
            <Configurations />
          </AuthGuard>
        }
      />

      <Route
        path="access-management"
        element={
          <AuthGuard>
            <AccessMangement />
          </AuthGuard>
        }
      />

      <Route
        path="access-management/add"
        element={
          <AuthGuard>
            <AddAccess />
          </AuthGuard>
        }
      />

      <Route
        path="access-management/edit/:id"
        element={
          <AuthGuard>
            <AddAccess type="edit" />
          </AuthGuard>
        }
      />

<Route
        path="/edit-profile"
        element={
          <AuthGuard>
            <Edit />
          </AuthGuard>
        }
      />

      <Route
        path="/edit-profile/change-password"
        element={
          <AuthGuard>
            <ChangePassword />
          </AuthGuard>
        }
      />

      <Route
        path="not-found"
        element={
          <AuthGuard>
            <NotFound />
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

export default routes;
