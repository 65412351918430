import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { postData, patchData, getData } from "../../../../../services";
import InputLayout from "../../../../atoms/InputLayout";
import SelectDropdown from "../../../../molecules/Dropdown";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import PageHeader from "../../../../atoms/PageHeader";
import PageCard from "../../../../atoms/PageCard";
import CustomButton from "../../../../atoms/CustomButton";
import Error from "../../../../atoms/Error";

import { calculateAmountWithGST } from "../../../../../helpers/amountWithGst";
import QuillEditor from "../../../../organisms/QuillEditorBasic";
import BackBtn from "../../../../atoms/BackBtn";
import { statusData } from "../../../../../helpers/staticData";

const Add = ({ type = "add" }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialVales, setInitialValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "PlanManagement/getPlanDetailsById",
        token: cookies.t,
        params: { id },
      });
      setInitialValues(res);
    };

    fetchData(); // Call the async function
  }, []);

  const initialData = {
    name: initialVales?.name || null,
    period_month:
      initialVales?.period_month != null ? initialVales?.period_month : null,
    period_day:
      initialVales?.period_day != null ? initialVales?.period_day : null,
    visitor_allowed: initialVales?.visitor_allowed || null,
    price: initialVales?.price
      ? initialVales?.price
      : initialVales?.price == 0
      ? 0
      : null || null,
    gst: initialVales?.gst
      ? initialVales?.gst
      : initialVales?.gst == 0
      ? 0
      : null || null,
    total_amount: initialVales?.total_amount || null,
    description: initialVales?.description || null,
    terms: initialVales?.terms || null,
    is_active: initialVales?.is_active,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Plan Name is required"),
    period_month: Yup.number().nullable(),
    period_day: Yup.number()
      .integer("Number of days must be a whole number")
      .nullable(),
    visitor_allowed: Yup.number().required("Visitors Count is Required"),
    price: Yup.number().required("Base Price is Required"),
    gst: Yup.number()
      .max(100, "GST percentage must be less than or equal to 100")
      .nonNullable("GST is Required")
      .required("GST is Required"),
    // total_amount: Yup.number().required("Total amount is required"),
    description: Yup.string().required("Description is required"),
    terms: Yup.string().nullable(),
  }).test(
    "atLeastOne",
    "Validity in Months or Days must be provided.",
    function (value) {
      if (!(value.period_month > 0 || value.period_day > 0)) {
        throw new Yup.ValidationError(
          "Validity in Months or Days must be provided.",
          value,
          "atLeastOne"
        );
      }
      return true; // Returning true to indicate that the validation passed
    }
  );

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      total_amount: parseFloat(
        calculateAmountWithGST(values.price, values.gst)
      ),
    };

    try {
      const res = postData({
        endpoint: "PlanManagement/addPlan",
        token: cookies.t,
        data,
      });
      if (res) {
        toast.success("Plan Created Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured", { style: errorStyles, duration: 1000 });
    }
  };

  const handleEdit = (values) => {
    const data = {
      ...values,
      period_month: values.period_month == "" ? null : values.period_month,
      period_day: values.period_day == "" ? null : values.period_day,
      total_amount: parseFloat(
        calculateAmountWithGST(values.price, values.gst)
      ),
    };

    try {
      const res = patchData({
        endpoint: "PlanManagement/editPlan",
        token: cookies.t,
        params: { id },
        data,
      });
      if (res) {
        toast.success("Plan Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured", { style: errorStyles, duration: 1000 });
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <>
          <PageHeader title={type === "add" ? "Create Plan" : "Edit Plan"}>
            <BackBtn />
          </PageHeader>

          <PageCard>
            <Form>
              <div className="row g-4">
                <InputLayout>
                  <label htmlFor="name">Plan Name</label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Plan Name"
                    className={`${
                      touched.name && errors.name ? "error-input" : ""
                    }`}
                  />
                  {touched.name && errors.name && <Error error={errors.name} />}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="period_month">Validity in Months</label>
                  <Field
                    type="number"
                    id="period_month"
                    name="period_month"
                    placeholder="Enter Validity in Months"
                    onKeyDown={(event) => {
                      if (
                        event.key === "-" ||
                        event.key === "e" ||
                        event.key === "+" ||
                        event.key === "." ||
                        event.key === "ArrowUp" ||
                        event.key === "ArrowDown"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    className={`${
                      touched.period_month &&
                      (errors.period_month || errors.atLeastOne)
                        ? "error-input"
                        : ""
                    }`}
                  />
                  {touched.period_month &&
                    (errors.period_month || errors.atLeastOne) && (
                      <Error error={errors.period_month || errors.atLeastOne} />
                    )}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="">Validity in Days</label>
                  <Field
                    type="number"
                    onKeyDown={(event) => {
                      if (
                        event.key === "-" ||
                        event.key === "e" ||
                        event.key === "+" ||
                        event.key === "." ||
                        event.key === "ArrowUp" ||
                        event.key === "ArrowDown"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    id="period_day"
                    name="period_day"
                    placeholder="Enter Validity in Days"
                    className={`${
                      touched.period_day &&
                      (errors.period_day || errors.atLeastOne)
                        ? "error-input"
                        : ""
                    }`}
                  />

                  {touched.period_day &&
                    (errors.period_day || errors.atLeastOne) && (
                      <Error error={errors.period_day || errors.atLeastOne} />
                    )}
                </InputLayout>
              </div>

              <div className="row g-4 mt-1">
                <InputLayout>
                  <label htmlFor="">Visitors Count</label>
                  <Field
                    type="number"
                    id="visitor_allowed"
                    onKeyDown={(event) => {
                      if (
                        event.key === "-" ||
                        event.key === "e" ||
                        event.key === "+" ||
                        event.key === "." ||
                        event.key === "ArrowUp" ||
                        event.key === "ArrowDown"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    name="visitor_allowed"
                    placeholder="Enter Visitors Count"
                    className={`${
                      touched.visitor_allowed && errors.visitor_allowed
                        ? "error-input"
                        : ""
                    }`}
                  />

                  {touched.visitor_allowed && errors.visitor_allowed && (
                    <Error error={errors.visitor_allowed} />
                  )}
                </InputLayout>
                <InputLayout>
                  <label htmlFor="">Base Price</label>
                  <Field
                    type="number"
                    id="price"
                    name="price"
                    onKeyDown={(event) => {
                      if (
                        event.key === "-" ||
                        event.key === "e" ||
                        event.key === "+" ||
                        event.key === "ArrowUp" ||
                        event.key === "ArrowDown"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter Base Price"
                    className={`${
                      touched.price && errors.price ? "error-input" : ""
                    }`}
                  />

                  {touched.price && errors.price && (
                    <Error error={errors.price} />
                  )}
                </InputLayout>


                <InputLayout>
                  <label htmlFor="">GST(%)</label>
                  <Field
                    type="number"
                    id="gst"
                    name="gst"
                    onKeyDown={(event) => {
                      if (
                        event.key === "-" ||
                        event.key === "e" ||
                        event.key === "+" ||
                        event.key === "ArrowUp" ||
                        event.key === "ArrowDown"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter GST(%)"
                    className={`${
                      touched.gst && errors.gst ? "error-input" : ""
                    }`}
                  />

                  {touched.gst && errors.gst && <Error error={errors.gst} />}
                </InputLayout>
              </div>

              <div className="row g-4 mt-1">
                <div className="col">
                  <InputLayout>
                    <label htmlFor="">Total Price incl. GST</label>
                    <Field
                      type="number"
                      id="total_amount"
                      name="total_amount"
                      placeholder="Total Price"
                      value={calculateAmountWithGST(values.price, values.gst)}
                      disabled
                      className={`${
                        touched.price && errors.price ? "error-input" : ""
                      }`}
                    />

                    {touched.price && errors.price && (
                      <Error error="Total Amount is Required" />
                    )}
                  </InputLayout>

                  {type === "edit" && (
                    <InputLayout classes="mt-3">
                      <label htmlFor="">Status</label>
                      <SelectDropdown
                        data={statusData}
                        placeholder="Select Status"
                        onChange={(option) => {
                          setFieldValue("is_active", option);
                        }}
                        selectedValue={initialData?.is_active}
                      />
                    </InputLayout>
                  )}
                </div>

                <InputLayout>
                  <QuillEditor
                    label="Description"
                    value={values.description}
                    placeholderText="Enter Description"
                    onChange={(content) => {
                      setFieldTouched("description", true);
                      content.length > 0 && content !== "<p><br></p>"
                        ? setFieldValue("description", content)
                        : setFieldValue("description", null);
                    }}
                    className={`${
                      touched.description && errors.description
                        ? "error-quill"
                        : ""
                    }`}
                  />
                  {touched.description && errors.description && (
                    <Error error={errors.description} />
                  )}
                </InputLayout>

                <InputLayout>
                  <QuillEditor
                    label="Terms And Condition"
                    placeholderText="Enter Terms And Conditions"
                    value={values.terms}
                    onChange={(content) => {
                      setFieldValue("terms", content);
                    }}
                  />
                </InputLayout>
              </div>

              {/* {console.log("values", values, "errors", errors)} */}

              <div className="d-flex gap-3 mt-2">
                {type === "add" ? (
                  <CustomButton
                    text="Create Plan"
                    iconRequired={false}
                    type="btn-primary"
                    buttonType="submit"
                    handleClick={() => handleSubmit(values)}
                    disabled={!isValid || !dirty}
                  />
                ) : (
                  <CustomButton
                    text="Edit Plan"
                    iconRequired={false}
                    type="btn-primary"
                    buttonType="submit"
                    handleClick={() => handleEdit(values)}
                    disabled={!isValid}
                  />
                )}

                <CustomButton
                  text="Cancel"
                  iconRequired={false}
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
            </Form>
          </PageCard>
        </>
      )}
    </Formik>
  );
};

export default Add;
