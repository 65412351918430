import React, { useEffect, useState } from "react";
import PageCard from "../../../../atoms/PageCard";
import SelectDropdown from "../../../../molecules/Dropdown";
import VisitorChart from "./VisitorChart";
import DateRangePickerShortCuts from "../../../../organisms/dateRangePicker";
import addMonths from "date-fns/addMonths";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";

const VisitorChartContainer = ({ businessData }) => {
  const [cookies] = useCookies(["t"]);

  const defaultToday = new Date();
  const [selectedBusiness, setSelectedBusiness] = useState("All");
  const [date, setDate] = useState({
    start_date: addMonths(defaultToday, -1).toISOString(),
    end_date: defaultToday.toISOString(),
  });

  const [graphData, setGraphData] = useState();

  const handleGraphUpdation = async () => {
    const res = await getData({
      endpoint: "SuperAdminDashboard/getVisitorChartData",
      params: {
        business:
          selectedBusiness === "All" ? null : JSON.stringify([selectedBusiness]),
        start_date: date?.start_date,
        end_date: date?.end_date,
      },
      token: cookies.b_t,
    });

    if(res){
        setGraphData(res?.counts)
    }
  };

  useEffect(() => {
    handleGraphUpdation();
  },[]);

  return (
    <PageCard>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="h2-i mb-4">Visitor Analytics</h3>
          <div className="d-flex gap-3">
            <SelectDropdown
              type="dashboard"
              placeholder="Select Venue"
              data={businessData}
              selectedValue={selectedBusiness}
              onChange={(option) => setSelectedBusiness(option)}
            />
            <DateRangePickerShortCuts setDate={setDate} />
            <button
              className="btn btn-primary"
              style={{ height: "46px" }}
              onClick={() => handleGraphUpdation()}
            >
              Update Graph
            </button>
          </div>
        </div>

        <VisitorChart data={graphData} />
      </div>
    </PageCard>
  );
};

export default VisitorChartContainer;
