import React from 'react'
import ValueLabelDisplay from '../../../../../../organisms/ValueLabelDisplay'

const View = ({data}) => {
  return (
    <>
    <ValueLabelDisplay label='User Name'>{data?.user_name}</ValueLabelDisplay>
    <ValueLabelDisplay label='Email Id'>{data?.email}</ValueLabelDisplay>
    <ValueLabelDisplay label='Title'>{data?.title}</ValueLabelDisplay>
    <ValueLabelDisplay label='Description'>{data?.description}</ValueLabelDisplay>
    <ValueLabelDisplay label='Requested At'>{data?.requested_at}</ValueLabelDisplay>
    </>
  )
}

export default View