import React, { useEffect, useState , useMemo, useCallback} from "react";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import CustomButton from "../../../../atoms/CustomButton";

import PageHeader from "../../../../atoms/PageHeader";
import BackBtn from "../../../../atoms/BackBtn";
import PageCard from "../../../../atoms/PageCard";
import PermissionSelector from "./PermissionSelector";
import InputLayout from "../../../../atoms/InputLayout";
import Error from "../../../../atoms/Error";
import { useNavigate, useParams } from "react-router-dom";
import { postData, getData , patchData} from "../../../../../services";

import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { useCookies } from "react-cookie";

const Add = ({ type = "add" }) => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "AdminConfiguration/getAllAccessByRole",
        token: cookies.t,
        params: { role_id: id },
      });
      setInitialValues(res);
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const modules = [
    { name: "Business", identifier: "business" },
    { name: "Subscription Plans", identifier: "subscription-plans" },
    { name: "Payments", identifier: "payments" },
    { name: "Configurations", identifier: "configuration" },
    { name: "Access Management", identifier: "access-management" },
  ];

  const initialData = {
    role_name: initialValues?.role || null,
    data: initialValues?.data || [],
  };

  const validationSchema = Yup.object().shape({
    role_name: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "AdminConfiguration/addRoleAccessManagementDetail",
        token: cookies.t,
        params: { role_name: values.role_name },
        data: values.data,
      });
      if (res) {
        toast.success("Role Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true)

    try {
      const res = await patchData({
        endpoint: "AdminConfiguration/editRoleAccessManagementDetail",
        token: cookies.t,
        params: { role_name: values.role_name , role_id : id},
        data: values.data,
      });
      if (res) {
        toast.success("Role Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }

  };
  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
      }) => (
        <>
          <PageHeader title={type === "add" ? "Add Role" : "Edit Role"}>
            <BackBtn />
          </PageHeader>

          <PageCard>
            <div className="row g-4 mb-4">
              <InputLayout>
                <label htmlFor="">Role</label>
                <Field
                  type="text"
                  id="role_name"
                  name="role_name"
                  placeholder="Enter Role"
                  className={`${
                    touched.role_name && errors.role_name ? "error-input" : ""
                  }`}
                />
                {touched.role_name && errors.role_name && (
                  <Error error={errors.role_name} />
                )}
              </InputLayout>

              <InputLayout></InputLayout>
              <InputLayout></InputLayout>
            </div>

            <ParentComponent modules={modules} initialData={initialData} values={values} setFieldValue={setFieldValue}/>

            <div className="d-flex gap-3 mt-4">
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={!isValid || !dirty || isSaving}
                >
                  Create Role
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving}
                >
                  Edit Role
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              {/* {console.log("values", values)} */}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </PageCard>
        </>
      )}
    </Formik>
  );
};

const ParentComponent = ({ modules, initialData, values, setFieldValue }) => {

  const memoizedSelectedModules = useMemo(() => {
    return modules.map((module) => ({
      module,
      selectedModule: initialData?.data?.find((item) => item.module === module.name) || {},
    }));
  }, [initialData, modules]);

  const handlePermissionChange = useCallback(
    (permissions) => {
      const updatedPermissions = [...values.data];
      const index = updatedPermissions.findIndex(
        (item) => item.module === permissions.module
      );
      if (index > -1) {
        updatedPermissions[index] = permissions;
      } else {
        updatedPermissions.push(permissions);
      }
      setFieldValue("data", updatedPermissions);
    },
    [values.data, setFieldValue]
  );

  return (
    <div>
      {memoizedSelectedModules.map(({ module, selectedModule }) => (
        <PermissionSelector
          key={module.name} // Make sure to add a unique key for each child component
          label={module.name}
          identifier={module.identifier}
          selected={selectedModule}
          onChange={handlePermissionChange}
        />
      ))}
    </div>
  );
};
export default Add;
