import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import PageHeader from "../../../../../atoms/PageHeader";
import Search from "../../../../../molecules/search";
import CustomButton from "../../../../../atoms/CustomButton";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import Pagination from "../../../../../molecules/Pagination";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Modal from "../../../../../organisms/modals/Modal";
import DeleteModal from "../../../../../organisms/modals/DeleteModal";
import { getData } from "../../../../../../services";
import { render } from "@testing-library/react";
import Edit from "../../../../../icons/Edit";
import Delete from "../../../../../icons/Delete";
import { useDelete } from "../../../../../../hooks/useDelete";
import View from "../../../../../icons/View";
import ViewRequest from "./View";
import NoContentIcon from "../../../../../icons/NocontentIcon";
import NoContentCard from "../../../../../atoms/NoContentCard";

const SupportRequests = () => {
  const [cookies] = useCookies(["t"]);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [modatState, setModalState] = useState({
    modalId: "",
    isViewModalOpen: false,
    data: null,
  });

  const getUserData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "FeedbackSupport/getAllFeedbackSupport",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handleView = (data) => {
    setModalState((prev) => ({
      ...prev,
      data: data,
      modalId: data?.id,
      isViewModalOpen: true,
    }));
  };

  const columns = [
    {
      field: "user_name",
      headerName: "Username",
      flex: 1,
      sortable: true,
    },
    {
      field: "business_email",
      headerName: "Email",
      flex: 1,
      sortable: true,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      sortable: true,
    },
    {
      field: "requestedAt",
      headerName: "Requested At",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            <div onClick={() => handleView(params.row)}>
              <View />
            </div>
          </div>
        );
      },
    },
  ];

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue && (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  return (
    <>
      <div className="d-flex space-between">
        <PageHeader title="Support Requests" type="small" />
        <div className="d-flex gap-3">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        getRowId={(row) => row.id}
        rows={tableData?.data || []}
        columns={columns}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="50vh"
      />

      {tableData.totalRecords > 25 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.totalRecords}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {modatState.isViewModalOpen && (
        <Modal
          title="View Request"
          isActive={modatState?.isViewModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewModalOpen: false,
            }))
          }
          width="642px"
        >
          <ViewRequest data={modatState?.data} />
        </Modal>
      )}
    </>
  );
};

export default SupportRequests;
