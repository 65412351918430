import React, { useState, useEffect } from "react";
import addMonths from "date-fns/addMonths";

import PageHeader from "../../../atoms/PageHeader";
import styles from "./styles.module.scss";
import InfoCard from "../../../atoms/InfoCard";

import Business from "../../../icons/Business";
import Payment from "../../../icons/Payment";
import Email from "../../../icons/Email";
import Message from "../../../icons/Message";
import VisitorChart from "./VisitorChart";
import RevenueChartContainer from "./RevenueChart";

import CustomDateRangePicker from "../../../organisms/dateRangePicker";

import { getBusinessList } from "../../../../services/basicGets";
import { useCookies } from "react-cookie";
import { getData } from "../../../../services";

const Home = () => {
  const [cookies] = useCookies(["t"]);
  const defaultToday = new Date();
  const [dashboardData, setDashboardData] = useState();
  const [selectedVenue, setSelectedVenue] = useState("All");
  const [date, setDate] = useState({
    start_date: addMonths(defaultToday, -1).toISOString(),
    end_date: defaultToday.toISOString(),
  });
  const [businessData, setBusinessData] = useState();

  useEffect(() => {
    (async () => {
      const resBusiness = await getBusinessList({
        token: cookies.t,
      });
      setBusinessData([{ value: "All", label: "All" }, ...resBusiness]);
    })();
  }, []);

  const getDashboardData = async () => {
    try {
      const res = await getData({
        endpoint: "SuperAdminDashboard/getAllDashboardData",
        params: {
          start_date: date?.start_date,
          end_date: date?.end_date,
        },
        token: cookies.t,
      });

      if (res) {
        setDashboardData(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [date]);

  return (
    <>
      <PageHeader title="Home">
        <CustomDateRangePicker setDate={setDate} />
      </PageHeader>
      <div className={styles.info_card_container}>
        <InfoCard
          color="#B1E5FC"
          title="Total Businesses"
          data={dashboardData?.businessCount || 0}
          svgIcon={<Business />}
          isSvg
          link='/dashboard/business'

          // data={data?.total_company || 0}
        />
        <InfoCard
          color="#FFD88D"
          title="Total Revenue"
          data={dashboardData?.revenueCount || 0}
          isSvg
          svgIcon={<Payment />}
          link='/dashboard/payments'

          // data={data?.total_company || 0}
        />
        <InfoCard
          color="#FFB7B7"
          isSvg
          svgIcon={<Email />}
          title="Total Emails"
          data={dashboardData?.emails || 0}

          // data={data?.total_company || 0}
        />
        <InfoCard
          color="#B5E4CA"
          title="Total SMS"
          isSvg
          svgIcon={<Message />}
          data={dashboardData?.sms || 0}
          // data={data?.total_company || 0}
        />
      </div>

      <div className="mt-4">
        <VisitorChart businessData={businessData} />
      </div>

      <div className="mt-4">
        <RevenueChartContainer businessData={businessData} />
      </div>
    </>
  );
};

export default Home;
