/* eslint-disable no-unused-vars */
import React, { useState , useEffect, useMemo} from "react";
import { useAuthenticated } from "../hooks/useAuthenticated";
import { useLocation, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { fetchAccessData } from "../store/slices/accessSlice";
import { decodeCookieValue } from "../helpers/cookieHelper";

const AuthGuard = (props) => {

  const accessData = useSelector((state) => state?.accessData?.accessData)

  const routePermissions = useMemo(() => [
    { route: '/dashboard/business', permission: accessData?.Business?.is_view },
    { route: '/dashboard/business/add', permission: accessData?.Business?.is_view && accessData?.Business?.is_create},
    { route: `/dashboard/business/edit`, permission: accessData?.Business?.is_view && accessData?.Business?.is_edit},
    { route: '/dashboard/plans', permission: accessData?.['Subscription Plans']?.is_view },
    { route: '/dashboard/plans/add', permission: accessData?.['Subscription Plans']?.is_view && accessData?.['Subscription Plans']?.is_create},
    { route: '/dashboard/plans/edit', permission: accessData?.['Subscription Plans']?.is_view && accessData?.['Subscription Plans']?.is_edit},
    { route: '/dashboard/payments', permission: accessData?.Payments?.is_view },
    { route: '/dashboard/payments/add', permission: accessData?.Payments?.is_view && accessData?.Payments?.is_create},
    { route: '/dashboard/access-management', permission: accessData?.['Access Management']?.is_view },
    { route: '/dashboard/access-management/add', permission: accessData?.['Access Management']?.is_view && accessData?.['Access Management']?.is_create},
    { route: '/dashboard/configurations', permission: accessData?.Configurations?.is_view },
  ], [accessData]);

  const [cookies] = useCookies('role')
  const { children, allowed } = props;
  const auth = useAuthenticated();
  const location = useLocation();
  const dispatch = useDispatch();

  const [requestedLocation, setRequestedLocation] = useState();

  useEffect(() => {
    if(cookies.role){
    dispatch(fetchAccessData(decodeCookieValue(cookies?.role || '')));   
    } 
  }, [cookies.role]);

  const hasPermission = useMemo(() => {
    
    let currentRoute = routePermissions.find(rp => rp.route === location.pathname);

    if (!currentRoute) {
      currentRoute = routePermissions.find(rp => {
        const regex = new RegExp(`^${rp.route?.replace(/\/:.*$/, '/[^/]+')}$`);
        return regex.test(location.pathname);
      });
    }
  
    // If still no match, check for a prefix match
    if (!currentRoute) {
      currentRoute = routePermissions
        .filter(rp => location.pathname?.startsWith(rp.route))
        .sort((a, b) => b.route.length - a.route.length)[0];
    }
  
    return currentRoute ? currentRoute.permission : true;
  }, [location.pathname, routePermissions]);

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth" />;
  }

  if (!hasPermission) {
    return <Navigate to="/dashboard/not-found" />; // Redirect to a "no access" page
  }


  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
