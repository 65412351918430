export const getValidityText = (validityMonths, validityDays) => {
    let periodMonthText = '';
    let periodDayText = '';
  
    if (validityMonths) {
      periodMonthText = `${validityMonths} ${validityMonths > 1 ? 'Months' : 'Month'}`;
    }
  
    if (validityDays) {
      periodDayText = `${validityDays} ${validityDays > 1 ? 'Days' : 'Day'}`;
    }
  
    // Combine the two parts
    if (periodMonthText && periodDayText) {
      return `${periodMonthText} ${periodDayText}`;
    } else if (periodMonthText) {
      return periodMonthText;
    } else if (periodDayText) {
      return periodDayText;
    } else {
      return null;
    }
  }