import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles, errorStyles } from "../assets/styles/toast";
import axios from "../axios";

export const useDelete = () => {

  const [cookies] = useCookies(['t'])

  const deleteItem = async (endpoint, name, params, invalidate) => {
    try {
      const response = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${cookies.t}`,
        },
        params: params,
      });

      // console.log('response',response)

      if (response.status === 200 && response.data.status) {
        toast.success(`${name} Deleted Successfully`, {
          duration: 1000,
          style: confimationStyles,
        });
      } else {
        toast.error(`${response.data.message}`, {
          duration: 2000,
          style: errorStyles,
        });
      }

      return response.data;
    } catch (error) {
      toast.error("An Error occurred", { duration: 2000, style: errorStyles });
    }
  };

  return deleteItem;
};
