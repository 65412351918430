import React, { useEffect, useState } from "react";
import Select from "react-select";
// import "../../../src/assets/scss/pages/CustomComponents.scss";
import {
  customDropdownStyles,
  dashboardStyles,
  filterStyles,
  errorStyles,
} from "./dropdown";

const SelectDropdown = ({
  id,
  multiSelect = false,
  selectedValue,
  onChange,
  classes,
  placeholder,
  data,
  format,
  isDisabled,
  isClearable,
  type,
  onClear,
  menuPlacement='bottom',
  isError,
  handleBlur = () => {}
}) => {
  const options = data || [];

  useEffect(() => {
    if (multiSelect && selectedValue) {
      const initialSelectedOptions = options.filter((option) =>
        selectedValue.includes(option.value)
      );
      // console.log('Initial Selected Options (MultiSelect):', initialSelectedOptions);
      setSelectedOptions(initialSelectedOptions);
    } else if (!multiSelect && selectedValue != null) {
      const initialSelectedOption =
        options.find((option) => option.value === selectedValue) || null;
      // console.log('Initial Selected Option (SingleSelect):', initialSelectedOption);
      setSelectedOptions(initialSelectedOption);
    } else {
      const defaultSelectedOptions = multiSelect ? [] : null;
      // console.log('Default Selected Options:', defaultSelectedOptions);
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [selectedValue, multiSelect, options]);

  // console.log(selectedValue , data)
  const [selectedOptions, setSelectedOptions] = useState(null);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    if (multiSelect) {
      if (selectedOptions.length === 0) {
        onClear();
      } else {
        const selectedValues = selectedOptions.map((option) => option.value);
        onChange(selectedValues);
      }
    } else {
      if (!selectedOptions) {
        onClear();
      }
      if (format === "all") {
        onChange(selectedOptions);
      } else {
        onChange(selectedOptions.value);
      }
    }
  };
  // console.log("type :",type)
  const styles = isError ? {...customDropdownStyles, ...errorStyles} : customDropdownStyles
  const filterStyle = {...customDropdownStyles, ...filterStyles}
  const dashboardStyle = { ...dashboardStyles}

  return (
    <Select
      className={`basic-single ${classes}`}
      classNamePrefix="select"
      options={options}
      value={selectedOptions}
      isMulti={multiSelect}
      onChange={handleSelectChange}
      onBlur={handleBlur}
      styles={type === 'filter' ? filterStyle : type === 'dashboard' ? dashboardStyle : styles}
      classes="varun"
      key={id}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
    />
  );
};

export default SelectDropdown;
