import React from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { patchData, postData } from "../../../../../../services";
import { statusData, userRoles } from "../../../../../../helpers/staticData";
import SelectDropdown from "../../../../../molecules/Dropdown";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";

const UserForm = ({ onClose, initialValues, id, refetchData }) => {
  const [cookies] = useCookies(["t"]);

  const initialData = {
    name: initialValues?.name || null,
    short_name: initialValues?.short_name || null,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Vehicle Name is required"),
    short_name: Yup.string().required("Short Name is required"),
  });

  const handleSubmit = async (values) => {
    // console.log("values", values);

    const res = await postData({
      endpoint: "AdminConfiguration/addVehicle",
      token: cookies.t,
      data: values,
    });

    if (res?.data?.status) {
      toast.success("Vehicle Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
      onClose();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "AdminConfiguration/editVehicle",
        token: cookies.t,
        data: values,
        params: { id: id },
      });

      if (res.status) {
        toast.success("Vehicle Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Vehicle Name</label>
            <Field type="text" id="name" name="name" placeholder="Enter Name" />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="email">Short Name (For Badge Print On App)</label>
            <Field
              type="text"
              id="short_name"
              name="short_name"
              placeholder="Enter Short Name"
            />
          </div>
          <div className="d-flex justify-content-end mt-3">
            {initialValues ? (
              <button
                type="button"
                className="btn btn-primary"
                disabled={!isValid}
                onClick={() => handleEdit(values)}
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary w-20"
                disabled={!isValid || !dirty}
              >
                Add
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
