import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik, Field } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Error from "../../../atoms/Error";
import { confimationStyles } from "../../../../assets/styles/toast";
import { getData, patchData, postData } from "../../../../services";
import { useCookies } from "react-cookie";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import InputLayout from "../../../atoms/InputLayout";
import { errorStyles } from "../../../molecules/Dropdown/dropdown";

const Edit = () => {
  const [cookies] = useCookies(["t", "uid"]);
  const [initialValues, setInitialValues] = useState();
  const initialData = {
    name: initialValues?.name || "",
    email: initialValues?.email || "",
    role_id: initialValues?.role_id || null,
    role: initialValues?.role || "",
    is_active: initialValues?.is_active,
  };

  const getProfileData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "AdminUserManagement/GetUserDetailById",
        params: {
          id: cookies.uid,
        },
        token: cookies.t,
      });

      if (res) {
        setInitialValues(res);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    const res = await patchData({
      endpoint: "AdminUserManagement/editUser",
      token: cookies.t,
      params: {
        id: cookies.uid,
      },
      data: {
        name: values?.name,
        email: values?.email,
        role_id: values?.role_id,
        is_active: true,
      },
    });
    if (res?.status) {
      toast.success("Details Updated Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast.error(res?.message, { style: errorStyles, duration: 1000 });
    }
  };
  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        isSubmitting,
      }) => (
        <>
          <PageHeader title="Edit Profile" />

          <PageCard classes="w-70">
            <div className="row">
              <InputLayout>
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                />
              </InputLayout>

              <InputLayout>
                <label htmlFor="email">Email</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  disabled
                />
              </InputLayout>

              <InputLayout>
                <label htmlFor="role">Role</label>
                <Field
                  type="text"
                  id="role"
                  name="role"
                  placeholder="Enter Role"
                  disabled
                />
              </InputLayout>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isValid || !dirty || isSubmitting}
                onClick={() => handleSubmit(values)}
              >
                Update
              </button>
            </div>
          </PageCard>
        </>
      )}
    </Formik>
  );
};

export default Edit;
