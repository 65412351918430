import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import PageHeader from "../../../../atoms/PageHeader";
import PageCard from "../../../../atoms/PageCard";
import BackBtn from "../../../../atoms/BackBtn";
import { getData } from "../../../../../services";
import InputLayout from "../../../../atoms/InputLayout";
import ImageSelector from "../../../../organisms/ImageSelector/ImageSelector";
import moment from "moment";

const View = () => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();

  const [businessData, setBusinessData] = useState();

  const ImageSelectorStyle = {
    borderRadius: "10px",
    border: "1px solid #EFEFEF",
    padding: "10px",
    objectFit: "contain",
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "BusinessManagement/getBusinessDetailsById",
        token: cookies.t,
        params: { id },
      });
      setBusinessData(res);
    };

    fetchData(); // Call the async function
  }, []);

  return (
    <>
      <PageHeader title="View Businesss">
        <BackBtn />
      </PageHeader>

      <PageCard classes="view-container">
        <div className="row mb-3">
          <div className="col-md">
            <label htmlFor="" className="mb-2">
              Logo
            </label>
            <ImageSelector
              type="Company"
              style={ImageSelectorStyle}
              notShow
              selectedImage={businessData?.logo}
            />
          </div>

          <div className="col-md"></div>
        </div>
        <div className="row g-4">
          <InputLayout>
            <label>Account ID</label>
            <div className="value">{businessData?.account_id}</div>
          </InputLayout>
          <InputLayout>
            <label>Business Name</label>
            <div className="value">{businessData?.name}</div>
          </InputLayout>

          <InputLayout>
            <label>Email Id</label>
            <div className="value">{businessData?.email}</div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <InputLayout>
            <label>Business Type</label>
            <div className="value">{businessData?.business_type}</div>
          </InputLayout>

          <InputLayout>
            <label>Total Venues</label>
            <div className="value">{businessData?.total_venues}</div>
          </InputLayout>

          <InputLayout>
            <label>Total Terminals</label>
            <div className="value">{businessData?.total_terminal}</div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <InputLayout>
            <label>Total Visitor Allowed</label>
            <div className="value">{businessData?.total_visitor_allowed}</div>
          </InputLayout>

          <InputLayout>
            <label>Total Visitor Count</label>
            <div className="value">{businessData?.visitor_count}</div>
          </InputLayout>

          <InputLayout>
            <label>GST NO.</label>
            <div className="value">{businessData?.gst_number}</div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <InputLayout>
            <label>Primary Phone Number</label>
            <div className="value">
              {businessData?.country_code}{" "}
              {businessData?.phone_number || 964353868}
            </div>
          </InputLayout>

          <InputLayout>
            <label>Alternate Phone Number</label>
            <div className="value">
              {businessData?.alternate_phone_number &&
                businessData?.country_code}{" "}
              {businessData?.alternate_phone_number}
            </div>
          </InputLayout>
          <InputLayout></InputLayout>
        </div>

        <div className="row g-4 mt-1 mb-4">
          <InputLayout>
            <label>Official Address</label>
            <div className="value">{businessData?.official_address}</div>
          </InputLayout>

          <InputLayout>
            <label>Registered Address</label>
            <div className="value">{businessData?.registered_address}</div>
          </InputLayout>
          <InputLayout></InputLayout>
        </div>

        <h4 className="fw-bolder">Contact Details</h4>
        <hr />

        <div className="row g-4 mb-4">
          <InputLayout>
            <label htmlFor="contact_name">Contact Person Name</label>
            <div className="value">{businessData?.contact_name}</div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Contact Person Number</label>
            <div className="value">
              {businessData?.contact_country_code}
              {businessData?.contact_phone_number}
            </div>
          </InputLayout>
          <InputLayout></InputLayout>
        </div>

        <h4 className="fw-bolder">Subscription Details</h4>
        <hr />

        <div className="row g-4 mb-4">
          <InputLayout>
            <label htmlFor="">Subscription Plan</label>
            <div className="value">{businessData?.plan_name}</div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Subscription Start Date</label>
            <div className="value">
              {moment
                .utc(businessData?.start_date)
                .format("DD/MM/YYYY hh:mm a")}
            </div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Subscription End Date</label>
            <div className="value">
              {moment.utc(businessData?.end_date).format("DD/MM/YYYY hh:mm a")}
            </div>
          </InputLayout>
        </div>
      </PageCard>
    </>
  );
};

export default View;
