import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import CustomButton from "../../../atoms/CustomButton";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { setLoading } from "../../../../store/slices/loaderSlice";
import View from "../../../icons/View";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";
import { getData } from "../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { useDelete } from "../../../../hooks/useDelete";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import { useSelector } from "react-redux";
import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";

const AccessMangement = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();

  const accessData = useSelector((data) => data?.accessData?.accessData);

  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const getAccessData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "AdminConfiguration/getAllAdminRoles",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getAccessData();
  }, [getAccessData]);

  const columns = [
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      sortable: true,
    },
  ];

  if (
    accessData?.["Access Management"]?.is_edit ||
    accessData?.["Access Management"]?.is_delete
  ) {
    columns.push({
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            {accessData?.["Access Management"]?.is_edit && (
              <div onClick={() => navigate(`edit/${params.row.id}`)}>
                <Edit />
              </div>
            )}

            {accessData?.["Access Management"]?.is_delete && (
              <div onClick={() => handleDeleteModal(params.row)}>
                <Delete />
              </div>
            )}
          </div>
        );
      },
    });
  }

  // deletion --------------------------------------------
  const deleteRole = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.role,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteRole("AdminConfiguration/deleteAdminRole", "Role", {
        role_id: modalState.modalId,
      });
      getAccessData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Role Added Yet!"
            subtitle="Click on Add Role button to add a Role"
            tag="Add Role"
            handleClick={() => navigate("add")}
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  return (
    <>
      <PageHeader title="Access Management" />
      <PageCard>
        <div className="mb-2 space-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />

          {accessData?.["Access Management"]?.is_create && (
            <CustomButton
              text="Add Role"
              type="btn-primary"
              handleClick={() => navigate("add")}
              CustomNoRowsOverlay={CustomNoRowsOverlay}
            />
          )}
        </div>

        <CustomDatagrid
          getRowId={(row) => row.id}
          rows={tableData?.data || []}
          columns={columns}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />
      </PageCard>

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Role"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default AccessMangement;
