import React, { useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import Brand from "../../../assets/branding/main-logo.png";
import CircleAvatar from "../../atoms/CircleAvatar";
import styles from "./styles.module.scss";
import { useCookies } from "react-cookie";
import LogoutModal from "../../organisms/modals/LogoutModal";

import Home from "../../icons/Home";
import Business from "../../icons/Business";
import Plans from "../../icons/Plans";
import Payment from "../../icons/Payment";
import Configuration from "../../icons/Configuration";
import { useSelector } from "react-redux";

const Navbar = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["un"]);
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);

  const accessDetails = useSelector((state) => state?.accessData?.accessData);

  const handleMenuOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };

  const handleMenuClose = (setter) => () => {
    setter(null);
  };

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path) ||
      (path === "/dashboard/home" && location.pathname === "/dashboard")
      ? styles.active_nav
      : "";
  };

  const routes = {
    home: {
      name: "Home",
      icon: <Home />,
    },
    business: {
      name: "Business",
      icon: <Business />,
    },
    plans: {
      name: "Subscription Plans",
      icon: <Plans />,
    },
    payments: {
      name: "Payments",
      icon: <Payment />,
    },
    configurations: {
      name: "Configurations",
      icon: <Configuration />,
    },
    "access-management": {
      name: "Access Management",
      icon: <Configuration />,
    },
  };

  // Function to filter routes based on permissions
  const getFilteredRoutes = (routes, permissions) => {
    const filteredRoutes = Object.keys(routes).reduce((acc, key) => {
      const routeName = routes[key].name;
      if (routeName === "Home" || permissions[routeName]?.is_view) {
        acc[key] = routes[key];
      }
      return acc;
    }, {});
    return filteredRoutes;
  };

  const filteredRoutes = getFilteredRoutes(routes, accessDetails);

  return (
    <div className={styles.navbar_container}>
      {isModalOpen && (
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <nav className={styles.navbar}>
        {/* Logo */}
        <div className={styles.icon} onClick={() => navigate("/dashboard")}>
          <img src={Brand} height="45px" alt="Logo" loading="eager" />
        </div>

        <ul className={` ${styles.navMenu} ${isActive ? styles.active : ""}`}>
          {Object.entries(filteredRoutes).map(([path, { name, icon }]) => (
            <li key={path} onClick={removeActive}>
              <NavLink
                to={path}
                className={`${styles.navLink} ${isLinkActive(
                  `/dashboard/${path}`
                )}`}
              >
                {icon}

                {name}
              </NavLink>
            </li>
          ))}
        </ul>

        <div className={styles.left_side}>
          <div onClick={handleMenuOpen(setProfileAnchorEl)}>
            <CircleAvatar name={cookies.un ? atob(cookies.un || '') : null} />
          </div>

          <div
            className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
            onClick={toggleActiveClass}
          >
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
          </div>
        </div>

        <Menu
          anchorEl={profileAnchorEl}
          open={Boolean(profileAnchorEl)}
          onClose={handleMenuClose(setProfileAnchorEl)}
          PaperProps={{
            style: {
              width: "200px",
              borderRadius: "16px",
              marginTop: "20px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/dashboard/edit-profile");
              setProfileAnchorEl(null);
            }}
          >
            Edit Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/edit-profile/change-password");
              setProfileAnchorEl(null);
            }}
          >
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setProfileAnchorEl(null);
            }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose(setMenuAnchorEl)}
          PaperProps={{
            style: {
              width: "220px",
              borderRadius: "16px",
              marginTop: "20px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          {["resource", "library", "faqs", "taxonomy", "settings"].map(
            (menu) => (
              <MenuItem
                key={menu}
                onClick={() => {
                  navigate(`/dashboard/${menu}`);
                  setMenuAnchorEl(null);
                }}
              >
                {menu.charAt(0).toUpperCase() + menu.slice(1)}
              </MenuItem>
            )
          )}
        </Menu>
      </nav>
    </div>
  );
};

export default Navbar;
